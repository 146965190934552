import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';

class AboutmePage extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang="en"
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO title="About me" />
                <TopHeading>About me</TopHeading>
                <article>
                    <p>
                        Hi, I am Nicolas Hollmann, a student from Hamburg,
                        Germany.
                    </p>
                    <p>
                        I study Master Computer Science at {' '}
                        <a
                            href="https://www.fh-wedel.de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            FH Wedel
                        </a>{' '} and also work there. 
                        Formerly I studied Computer Games Technology likewise at the FH Wedel.
                    </p>
                    <p>
                        Previously I also worked at{' '}
                        <a
                            href="https://mondula.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Mondula
                        </a>{' '}
                        as a developer.
                    </p>
                </article>
            </Layout>
        );
    }
}

export default AboutmePage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
